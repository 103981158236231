<template>
  <div  class="baloo_paaji_regular recursos">
    <section class="secaoRecurso">
      <v-container>
        <v-row>
          <v-col cols="12">
            <h1 class="_font_2_5rem" style="color: #000"><b>Alguns recursos do Uaiped</b></h1>
            <!--<h2 class="_font_2rem" style="color: #C9182A">Conheça alguns recursos do Uaiped.</h2>-->
            <!--<br>-->
            <pro-divisor></pro-divisor>
          </v-col>

          <v-col cols="12" sm="12" md="4" lg="4">
            <v-list three-line style="background: transparent; ">
              <template v-for="(item, index) in lista1">
                <v-list-item :key="'index'+index" style="text-align: right">
                  <v-list-item-content>
                    <h3 class="_font_1_5rem" style="color: #000" data-aos="slide-right"><b>{{item.titulo}}</b></h3>
                    <span class="_font_1_2rem" style="color: #C9182A"  data-aos="slide-right">{{item.descricao}}</span>
                  </v-list-item-content>
                  <v-list-item-avatar size="60" rounded data-aos="slide-down">
                    <v-btn  color="#000" outlined  fab  :name="'rec_'+index">
                      <v-icon size="30" >{{item.src}}</v-icon>
                    </v-btn>
                  </v-list-item-avatar>
                </v-list-item>
              </template>
            </v-list>
          </v-col>
          <v-col cols="12" sm="12" md="4" lg="4">
            <!--<v-img :src="img" contain height="500"  data-aos="zoom-in"></v-img>-->
            <v-carousel hide-delimiters show-arrows-on-hover  cycle>
              <v-carousel-item v-for="(item,i) in listaPrints" :key="i">
                <v-img :src="item" contain height="500"  data-aos="zoom-in"></v-img>
              </v-carousel-item>
            </v-carousel>
          </v-col>
          <v-col cols="12" sm="12" md="4" lg="4">
            <v-list three-line style="background: transparent; ">
              <template v-for="(item, index) in lista2">
                <v-list-item :key="'index2'+index" style="text-align: left">
                  <v-list-item-avatar size="60" rounded data-aos="slide-down">
                    <v-btn  color="black" outlined  fab :name="'ic'+index">
                    <v-icon size="30">{{item.src}}</v-icon>
                    </v-btn>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <h3 class="_font_1_5rem"  style="color: #292929"  data-aos="slide-right"><b>{{item.titulo}}</b></h3>
                    <span class="_font_1_2rem" style="color: #C9182A"   data-aos="slide-right">{{item.descricao}}</span>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section>
      <v-container>
        <v-row>
          <v-col cols="12">
            <h1 class="_font_2_5rem cor_red" data-aos="fade-down"><b>Todos os recursos disponiveis</b></h1>
            <br>
            <pro-divisor></pro-divisor>
          </v-col>
        </v-row>

        <!--<v-row justify="center">-->
          <!--<v-col cols="12" sm="6" md="4" lg="2" v-for="(item, index) in lista3" :key="'cartela_'+index">-->
            <!--<img-card-flip :front="item.src" height="160px" size="50" :isImg="false" :hasText="true"  :texto="item.titulo"-->
                           <!--:tipo="index%2===0 ? 'Y' : 'X'">-->
              <!--<div style=" text-align: center; line-height: normal;  padding-top: 5%;">-->
                <!--<p style="color: white;font-size: 13px;"> {{item.descricao}}</p>-->
                <!--&lt;!&ndash;<v-btn outlined dark  @click="irParaScroll('cartela','cartela_' + item.cartela_id,'cartelaScroll')">Saiba +</v-btn>&ndash;&gt;-->
              <!--</div>-->
            <!--</img-card-flip>-->
          <!--</v-col>-->
        <!--</v-row>-->
      </v-container>
    </section>
    <section class="secaoRecursoBottom" >
      <v-container>
        <v-card style="background: transparent!important;" flat>
          <v-expand-transition>
            <v-card flat width="100%" class="transicao" :style="'background:transparent;overflow: hidden; min-height: 300px; max-height:'+max_height">
              <v-row justify="center">
                <v-col cols="12" sm="4" md="3" lg="2" v-for="(item, index) in lista3" :key="'cartela_'+index" >
                  <div data-aos="zoom-out-up">
                    <img-card-flip :front="item.src" height="160px" size="50" :isImg="false" :hasText="true"  :texto="item.titulo"
                                   :tipo="index%2===0 ? 'Y' : 'X'">
                      <div style=" text-align: center; line-height: normal;  padding-top: 5%;">
                        <p style="color: white;font-size: 13px;"> {{item.descricao}}</p>
                      </div>
                    </img-card-flip>
                  </div>
                </v-col>
              </v-row>
              <br>
            </v-card>
          </v-expand-transition>

          <v-card-actions>
            <div style="width: 100%; text-align: center" id="valor">
              <v-btn name="ocultar" outlined dark color="red" @click="redimensionar()">{{expandir ? 'Ocultar' : 'Mostrar todos os recursos'}}</v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-container>
    </section>

    <!--<section class="secaoRecursoBottom" id="secao_rec">-->
      <!--<v-container>-->
        <!--<v-card>-->
          <!--<v-expand-transition>-->
            <!--<v-card flat-->
              <!--width="100%"-->
              <!--class="transicao"-->
              <!--:style="'overflow: hidden; min-height: 400px; max-height:'+max_height"-->
            <!--&gt;-->
              <!--<v-row>-->
                <!--<v-col cols="12" sm="6" md="4" v-for="(item, index) in lista3"  :key="'index3'+index">-->
                  <!--<v-list-item style="text-align: left">-->
                    <!--<v-list-item-avatar  rounded data-aos="slide-right">-->

                      <!--<v-icon color="green" class="icom_rec">{{item.src}}</v-icon>-->
                    <!--</v-list-item-avatar>-->
                    <!--<v-list-item-content>-->
                      <!--<h3 class="_font_1_5rem"  data-aos="slide-right"> {{index+1}} - <b>{{item.titulo}}</b></h3>-->
                      <!--<span class="_font_1_2rem"  data-aos="slide-right">{{item.descricao}}</span>-->
                    <!--</v-list-item-content>-->
                  <!--</v-list-item>-->
                <!--</v-col>-->
              <!--</v-row>-->
            <!--</v-card>-->
          <!--</v-expand-transition>-->
          <!--<v-card-actions>-->
            <!--<div style="width: 100%; text-align: center">-->
              <!--<v-btn outlined dark color="red" @click="redimensionar()">{{expandir ? 'Ocultar' : 'Mostrar toddos os recursos'}}</v-btn>-->
            <!--</div>-->
          <!--</v-card-actions>-->
        <!--</v-card>-->
      <!--</v-container>-->
    <!--</section>-->
  </div>
</template>

<script async>
/* eslint-disable */
  import img from '../../assets/img/celular.png'
  import img1 from '../../assets/img/prints/a1.png'
  import img2 from '../../assets/img/prints/a2.png'
  import img3 from '../../assets/img/prints/a3.png'
  import img4 from '../../assets/img/prints/a4.png'
  import img5 from '../../assets/img/prints/a5.png'
  import img6 from '../../assets/img/prints/a6.png'
  import miscMixin from '../../helpers/misc'


  export default {
    name: 'SecaoCidade',
    mixins: [miscMixin],
    data: () => ({
      img,
      expandir:false,
      listaPrints: [img3,img4,img6,img1,img5,img2],
      lista1: [
        {
          titulo: 'Painel de controle',
          descricao: 'Dispensa uso de computadores.',
          src: 'mdi-solar-panel'
        },
        {
          titulo: 'Atualizações de preços',
          descricao: 'Seus preços serão atualizados em tempo real.',
          src: 'mdi-currency-usd'
        },
        {
          titulo: 'Personalização',
          descricao: 'Personalize seu cardápio com fotos de produtos, categorias, logomarcas e cores da sua empresa.',
          src: 'mdi-theme-light-dark'
        },
        {
          titulo: 'Agendamento de pedidos',
          descricao: 'Seu cliente pode agendar a data e hora desejada para a entrega.',
          src: 'mdi-calendar-month-outline'
        },
      ],
      lista2: [
        {
          titulo: 'Versão simples e completa',
          descricao: 'Você pode optar por uma versão mais simples de seus produtos, sem custo adicional.',
          src: 'mdi-counter'
        },
        {
          titulo: 'Link para compartilhamento',
          descricao: 'Compartilhe seu cardápio de forma fácil em qualquer rede social.',
          src: 'mdi-share-variant'
        },
        {
          titulo: 'Banners',
          descricao: 'Adicione banners de forma ilimitada em sua página principal.',
          src: 'mdi-image'
        },
        {
          titulo: 'Destaque e promoções',
          descricao: 'Classifique seus produtos que estiverem em destaque, ou em promoções.',
          src: 'mdi-sale'
        },
      ],
      lista3:[
        {titulo:  'Pedidos ilimitados direto no WhatsApp ', descricao:'Seus pedidos chegam direto no WhatsApp de seu estabelecimento', src: 'mdi-whatsapp'},
        {titulo:  'Cadastro de produtos ilimitado', descricao:'Cadastro de produtos ilimitado', src: 'mdi-archive-settings-outline'},
        {titulo:  'Troco para pagamento em dinheiro', descricao:'Calculo automático do troco de acordo com valor em dinheiro informado', src: 'mdi-cash'},
        {titulo:  'Fretes diferentes por bairro', descricao:'Possibilidade de cadastrar valores de frete diferente para cada localidade de entrega', src: 'mdi-moped'},
        {titulo:  'Fotos dos produtos e categorias', descricao:'Cadastre fotos de seus produtos e suas categorias', src: 'mdi-shape-outline'},
        {titulo:  'Ordem nos produtos, seções e categorias.', descricao:'Organização por categorias, seções ou produtos', src: 'mdi-format-list-numbered'},
        {titulo:  'Bloqueio de produtos e seções', descricao:'Você pode bloquear produtos ou seções inteiras, de acordo com sua necessidade.', src: 'mdi-book-cancel-outline'},
        {titulo:  'Agendar delivery ou retirada', descricao:'Você tem a opção de permitir que os clientes agendem a retirada ou entrega dos produtos mesmo que o estabelecimento esteja fechado', src: 'mdi-clipboard-text-clock'},
        {titulo:  'Organização por categorias', descricao:'Organize sua tela por categorias', src: 'mdi-order-alphabetical-ascending'},
        {titulo:  'Formas de pagamento', descricao:'Configure as formas de pagamento aceitas pelo estabelecimento.', src: 'mdi-credit-card-marker-outline'},
        {titulo:  'Bloquear vendas fora do horário', descricao:'Você tem a opção de não receber pedidos fora do horário estabelecido.', src: 'mdi-block-helper'},
        {titulo:  'Versões light ou full.', descricao:'Voce pode configurar o sistema para uma exibição simples ou completa', src: 'mdi-card-bulleted-settings'},
        {titulo:  'Personalização', descricao:'Opção de personalização de cores e logomarca', src: 'mdi-palette'},
        {titulo:  'Banners promocionais ilimitados', descricao:'Cadastre banners promocionais para destaques.', src: 'mdi-panorama-variant-outline'},
        {titulo:  'Redes Sociais.', descricao:'Crie links para o compartilhamento de seu catalogo em suas redes sociais', src: 'mdi-account-group-outline'},
        {titulo:  'Fechar temporariamente a loja.', descricao:'Opção de fechar temporariamente a loja.', src: 'mdi-store-clock'},
        {titulo:  'Clonagem de seções.', descricao:'Permite a duplicação de uma seção para agilizar o cadastro', src: 'mdi-content-duplicate'},
        {titulo:  'Variações de um mesmo produtos', descricao:'Permite que você selecione itens adicionais dentro de seçôes para um mesmo produto', src: 'mdi-format-list-group'},

      ],
      modal: false,
      max_height: '50vh',
    }),
    methods: {
      redimensionar(){
        this.expandir = !this.expandir
        if ( this.expandir) {
          var secao_rec = document.getElementById('secao_rec')
          var w = window.screen.availWidth
          var h = window.screen.availHeight
          // 1vh = 7,57
          var vh = parseInt(h * 0.13210039630118892)

          // console.log(window.screen.availHeight)

            // 1600 - 160vh
            // 1500 - 165vh
            // 1400 - 170vh
            // 1300 - 175vh
          // console.log('w: ' + w + ' - h: ' + h)
          if (w >= 1 && w <=900) {
            vh = parseInt(w * 0.13210039630118892)
            // console.log('* Vh: ', vh)
            // this.max_height ='500vh'
            this.max_height = (100+(vh *parseInt(w/10)))+'vh'

          } else {
            // console.log('Vh: ', vh)
            this.max_height = (105+vh)+'vh'
          }
          // console.log('max_height: ',this.max_height)
          // 200 = 4 it
          // 300 = 6 it
          // 400 = 6 it
          // 500 = 7 it
          // 600-800 = 10 it 2col
          // 900= 12 it 2col
          // 1000= 15 it 3col
        } else {
          this.max_height = '50vh'
        }


      }
    },
    mounted() {

    }
  }
</script>
